interface IProductLinks {
  eula: string;
  productOverview: string;
  productDocumentation: string;
}

const links: IProductLinks = {
  eula: 'https://www.47lining.com/paas/docs/preview/preview-docs-audience/latest/preview-docs/commercial-terms/eula/',
  productOverview:
    'https://www.47lining.com/paas/products/products-audience/latest/preview/enterprise-paas-preview/',
  productDocumentation:
    'https://www.47lining.com/paas/docs/preview/preview-docs-audience/latest/',
};

const footerLinks = [
  { text: 'Terms of Use', path: links.eula },
  {
    text: 'Privacy Policy',
    path: 'https://www.hitachivantara.com/en-us/company/legal.privacy.html',
  },
  { text: 'Legal', path: 'https://www.hitachivantara.com/en-us/company/legal.html' },
];

export { links, footerLinks };
