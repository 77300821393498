import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function LogInPage() {
  const [searchParams] = useSearchParams();
  const codeParam = searchParams.get('code');
  const stateParam = searchParams.get('state');
  const cognitoUrl = process.env.REACT_APP_COGNITO_DOMAIN || '';

  function getCognitoAuth() {
    const cognitoAuthUrl = `${cognitoUrl}/login`;
    const searchParams = new URLSearchParams();
    searchParams.append('redirect_uri', `${process.env.REACT_APP_REDIRECT_URL}/login`);
    searchParams.append('response_type', 'code');
    searchParams.append('client_id', process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID || '');
    searchParams.append('identity_provider', 'COGNITO');
    searchParams.append(
      'scope',
      'email profile openid osduOnAws/osduOnAWSService aws.cognito.signin.user.admin'
    );
    return `https://${cognitoAuthUrl}?${searchParams.toString()}`;
  }

  useEffect(() => {
    if (!codeParam || !stateParam) {
      return window.location.replace(getCognitoAuth());
    }
  }, []);

  return (
    <div>
      <p>Wait for redirection to sign in form</p>
    </div>
  );
}
