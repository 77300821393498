import { ReactNode } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import LogoAWS from 'layout/assets/Images/Amazon_Web_Services_Logo.svg';
import Logo47 from 'layout/assets/Images/47L_single.svg';
import LogoLaunchpad from 'layout/assets/Images/Ecosystem_Launchpad_White.svg';
import useAuthenticatedUser from 'hooks/useAuthenticatedUser';
import * as S from './TopBar.style';

interface ITopBar {
  onSidebarOpen?: () => void;
  userMenu?: ReactNode;
  menuIcon?: ReactNode;
  toggleSideBar?: () => void;
  helpMenu?: ReactNode;
}

enum WORKFLOW_TYPE {
  WORKFLOW_WELLDATA = 'WORKFLOW_WELLDATA',
  WORKFLOW_SEISMIC_DATA = 'WORKFLOW_SEISMIC_DATA',
  WORKFLOW_C = 'WORKFLOW_C',
  WORKFLOW_INTERICA = 'WORKFLOW_INTERICA',
  WORKFLOW_QUESTLABS = 'WORKFLOW_QUESTLABS',
  WORKFLOW_IKON_SCIENCE = 'WORKFLOW_IKON_SCIENCE',
  WORKFLOW_INT = 'WORKFLOW_INT',
}

const headers = {
  [WORKFLOW_TYPE.WORKFLOW_WELLDATA]: 'Well Data Interpretation and Synthesis Interoperability',
  [WORKFLOW_TYPE.WORKFLOW_C]: 'MOCK WORKFLOW_C',
  [WORKFLOW_TYPE.WORKFLOW_INTERICA]: 'Interica Workflow',
  [WORKFLOW_TYPE.WORKFLOW_QUESTLABS]: 'Questlabs Workflow',
  [WORKFLOW_TYPE.WORKFLOW_SEISMIC_DATA]: 'Seismic Data Workflow',
  [WORKFLOW_TYPE.WORKFLOW_IKON_SCIENCE]: 'Ikon Science Workflow',
  [WORKFLOW_TYPE.WORKFLOW_INT]: 'INT Workflow',
};

export default function TopBar({ userMenu, menuIcon, toggleSideBar, helpMenu }: ITopBar) {
  const { session } = useAuthenticatedUser();
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const selectedHeader = headers[process.env.REACT_APP_WORKFLOW_TYPE as WORKFLOW_TYPE];

  return (
    <S.StyledAppBar>
      <S.StyledToolbar>
        <S.Container>
          {toggleSideBar && !isDesktop && (
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={toggleSideBar}
              color="inherit"
              size="large"
            >
              {menuIcon}
            </IconButton>
          )}
          <S.LogoLink to="/home">
            <S.LogoContainer>
              <S.AWSLogo src={LogoAWS} alt={'Amazon Web Services'} />
              <S.FortySevenLogo src={Logo47} alt={'47Lining PaaS'} />
            </S.LogoContainer>
          </S.LogoLink>
        </S.Container>
        <S.Container>
          <S.SimpleLink to={'/home'}>
            <S.LaunchpadLogo src={LogoLaunchpad} alt={'Ecosystem Launchpad'} />
            <S.Header>{selectedHeader}</S.Header>
          </S.SimpleLink>
        </S.Container>
        {session && (
          <S.Container>
            {(helpMenu || userMenu) && isDesktop && (
              <S.RightContainer>
                <S.HelpMenuContainer>{helpMenu}</S.HelpMenuContainer>
                <S.UserMenuContainer>{userMenu}</S.UserMenuContainer>
              </S.RightContainer>
            )}
          </S.Container>
        )}
      </S.StyledToolbar>
    </S.StyledAppBar>
  );
}
