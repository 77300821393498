import { useState, ReactElement, useEffect, useContext } from 'react';
import { Auth } from '@aws-amplify/auth';
import { footerLinks } from 'configuration';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { OSDUContext } from '@paas-47lining/layout';
import { useLocation, useNavigate } from 'react-router-dom';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import CloseIcon from '@mui/icons-material/Close';
import CommonMenu from 'layout/components/TopBar/Components/CommonMenu/CommonMenu';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import SideBar from 'layout/components/SideBar/SideBar';
import SideBarItem from 'layout/components/SideBar/Components/SideBarItem/SideBarItem';
import TopBar from 'layout/components/TopBar/TopBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Footer, Toast } from '@paas-47lining/layout';
import { Home, Info } from '@mui/icons-material';
import useAuthenticatedUser from 'hooks/useAuthenticatedUser';
import { IDeploymentModule } from 'types/Deployment';
import MenuElementWithMenu from './MenuElementWithMenu';
const { default: DeploymentPages }: IDeploymentModule = await import(
  `pages/${process.env.REACT_APP_WORKFLOW_TYPE}`
);
import jwtParser from 'Utils/jwtParser';
import { IPage } from 'types/Page';
import { CircularProgress } from '@mui/material';
import * as S from './Navigation.style';

interface NavigationProps {
  children: ReactElement;
}

export default function Navigation({ children }: NavigationProps) {
  const { pathname } = useLocation();
  const { session, attributes } = useAuthenticatedUser();
  const username = 'username' in attributes ? attributes.username : '';
  const [openSidebar, setOpenSidebar] = useState(false);
  const navigate = useNavigate();
  const { setDataPartitionId, dataPartitionId, setToastState } = useContext(OSDUContext);

  const isDesktop = useMediaQuery('(min-width: 1024px)', {
    defaultMatches: true,
  });
  const toggleSideBar = () => setOpenSidebar((prev) => !prev);
  const handleSidebarClose = () => setOpenSidebar(false);

  function getMainSideItems(pages: IPage[]) {
    const items: Array<JSX.Element> = [];
    for (const item of pages) {
      const { index, path, exactPath, name, icon, subMenu } = item;
      if (!icon && !name) {
        continue;
      }
      const Icon =
        icon && typeof icon === 'string'
          ? () => <S.IconImage props={{ isBig: !name }} src={icon} alt={name || 'icon'} />
          : icon;

      const Element = (
        <SideBarItem
          key={index}
          isActive={exactPath ? exactPath === pathname : pathname.includes(path)}
          onClick={() => navigate(path)}
          name={name || ''}
          icon={<Icon />}
        />
      );
      if (subMenu) {
        items.push(
          <MenuElementWithMenu key={index} menu={getMainSideItems(subMenu)}>
            {Element}
          </MenuElementWithMenu>
        );
      } else {
        items.push(Element);
      }
    }
    return items;
  }
  const discoveryMainSideItems = [
    <SideBarItem
      key={'discovery'}
      name={'Home'}
      icon={<Home />}
      isActive={pathname.includes('/Home')}
      onClick={() => navigate('/Home')}
    />,
    <SideBarItem
      key={'Workflow'}
      name={'Workflow'}
      icon={<Info />}
      onClick={() => navigate('/workflow')}
      isActive={pathname.toLocaleLowerCase().includes('/workflow')}
    />,
    ...getMainSideItems(DeploymentPages),
  ];

  const userMenu = (
    <CommonMenu
      icon={<S.AccountIcon />}
      title={username}
      menuOptions={[
        {
          name: `Partition ID: ${dataPartitionId}`,
          action: () => {
            navigator.clipboard.writeText(dataPartitionId);
            setToastState({
              errorSeverity: 'success',
              message: 'Data partition ID copied to clipboard',
            });
          },
          icon: <FileCopyIcon />,
        },
        {
          name: 'Copy token',
          action: () => {
            Auth.currentSession().then((x) => {
              navigator.clipboard.writeText('Bearer ' + x.getAccessToken().getJwtToken());
              setToastState({ errorSeverity: 'success', message: 'Token copied to clipboard.' });
            });
          },
          icon: <AssignmentOutlinedIcon />,
        },
        {
          name: 'Sign out',
          action: () => Auth.signOut({ global: true }).then(() => navigate('/Home')),
          icon: <ExitToAppOutlinedIcon />,
        },
      ]}
    />
  );

  useEffect(() => {
    Auth.currentSession()
      .then((x) => {
        const rawToken = x.getIdToken().getJwtToken();
        const parsed = jwtParser(rawToken);
        const dataPartitionId = 'partition_id' in parsed ? parsed['partition_id'] : 'osdu';
        setDataPartitionId(dataPartitionId as string);
      })
      .catch(() => setDataPartitionId('osdu'));
  }, [session]);

  return (
    <S.Wrapper>
      <S.SkipLink href="#main-content">Skip to main content</S.SkipLink>
      <TopBar
        menuIcon={openSidebar ? <CloseIcon /> : <MenuIcon />}
        toggleSideBar={toggleSideBar}
        userMenu={userMenu}
      />
      {session && (
        <SideBar
          variant="persistent"
          open={isDesktop ? true : openSidebar}
          onClose={handleSidebarClose}
          userMenu={userMenu}
          items={discoveryMainSideItems}
        />
      )}
      <S.Container props={{ isLoggedIn: Boolean(session) }}>
        <S.Content id="main-content">
          {session === undefined ? (
            <S.LoadingScreen>
              <S.LoadingLabel>Authenticating...</S.LoadingLabel>
              <CircularProgress size={48} color="primary" />
            </S.LoadingScreen>
          ) : (
            children
          )}
        </S.Content>
        <Toast />
        <Footer footerLinks={footerLinks} />
      </S.Container>
    </S.Wrapper>
  );
}
