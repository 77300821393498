import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import LogInPage from 'pages/LogInPage';
import { Auth } from '@aws-amplify/auth';
import { HomePage } from './pages';
import { IDeploymentModule } from 'types/Deployment';
import JWTPage from 'pages/JWTPage/JWTPage';
const {
  default: DeploymentPages,
  workflow: { Component: WorkflowPage },
  subMenu,
}: IDeploymentModule = await import(`pages/${process.env.REACT_APP_WORKFLOW_TYPE}`);

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const { pathname } = useLocation();
  const loggedInRouter = (
    <Routes>
      <Route path={'/Home'} element={<HomePage />} />
      <Route path={'/workflow'} element={<WorkflowPage />} />
      {DeploymentPages.filter(({ Component }) => Boolean(Component)).map(
        ({ index, path, Component, subMenu }) => {
          const mainRoute = <Route key={index} path={path} element={<Component />} />;
          const subRoutes = subMenu
            ? subMenu.map(({ Component: SubComponent, path: subPath }, index) => (
                <Route key={subPath + index} path={subPath} element={<SubComponent />} />
              ))
            : [];
          return [mainRoute, ...subRoutes];
        }
      )}
      {subMenu &&
        subMenu.pages
          .filter(({ Component }) => Boolean(Component))
          .map(({ index, path, Component }) => (
            <Route key={index} path={path} element={<Component />} />
          ))}
      <Route path={'/jwt'} element={<JWTPage />} />
      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  );

  const authRouter = (
    <Routes>
      <Route path="/login" element={<LogInPage />} />
      <Route path="/logout" element={<div>logged out</div>} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setIsLoggedIn(user ? true : false);
      })
      .catch(() => {
        setIsLoggedIn(false);
      });
  }, [pathname]);

  return isLoggedIn ? loggedInRouter : authRouter;
}
