import { useState } from 'react';
import { Popper, useMediaQuery } from '@mui/material';
import Fade from '@mui/material/Fade';
import * as S from './Navigation.style';

export default function MenuElementWithMenu({
  children,
  menu,
}: {
  children: JSX.Element;
  menu: JSX.Element | Array<JSX.Element>;
}) {
  const [ref, setRef] = useState<null | HTMLElement>(null);
  const isWide = useMediaQuery('(min-width: 1024px)');
  const isTablet = useMediaQuery('(min-width: 900px)');

  const placement = isWide ? 'right-start' : isTablet ? 'right-end' : 'bottom';
  return (
    <S.MenuElementWithMenuContainer
      onMouseEnter={(e) => setRef(e.currentTarget)}
      onMouseLeave={() => setRef(null)}
    >
      {children}
      <Popper
        style={{ zIndex: 1300 }}
        placement={placement}
        open={Boolean(ref)}
        anchorEl={ref}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <S.SideMenuContainer>{menu}</S.SideMenuContainer>
          </Fade>
        )}
      </Popper>
    </S.MenuElementWithMenuContainer>
  );
}
