import { Auth } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

const redirectUrl = process.env.REACT_APP_REDIRECT_URL;

export default {
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: [
        'email',
        'profile',
        'openid',
        'osduOnAws/osduOnAWSService',
        'aws.cognito.signin.user.admin',
      ],
      mandatorySignIn: true,
      redirectSignIn: `${redirectUrl}/login`,
      redirectSignOut: `${redirectUrl}/logout`,
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: 'api',
        endpoint: process.env.REACT_APP_BASE_API_URL,
        region: process.env.REACT_APP_REGION,
        custom_header: async () => {
          const session = await Auth.currentSession();
          const idTokenExpire = session.getIdToken().getExpiration();
          const currentTimeSeconds = Math.round(+new Date() / 1000);
          const willExpireInSeconds = idTokenExpire - currentTimeSeconds;
          if (willExpireInSeconds < 100) {
            const user = await Auth.currentAuthenticatedUser();
            user.refreshSession(
              session.getRefreshToken(),
              (err: unknown, data: CognitoUserSession) => {
                if (err) {
                  Auth.signOut();
                  return {};
                } else {
                  console.log('refreshed user session');
                  return { Authorization: `Bearer ${data.getAccessToken().getJwtToken()}` };
                }
              }
            );
          }
          return { Authorization: `Bearer ${session.getAccessToken().getJwtToken()}` };
        },
      },
    ],
  },
};
