import * as S from './SideBarItem.style';

interface SideBarItemProps {
  name: string;
  icon?: JSX.Element;
  isActive?: boolean;
  onClick?: () => void;
}

export default function SideBarItem({ name, icon, isActive, onClick }: SideBarItemProps) {
  return (
    <S.StyledButton props={{ isActive }} onClick={onClick}>
      {icon && <S.Icon>{icon}</S.Icon>}
      {name}
    </S.StyledButton>
  );
}
