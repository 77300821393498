import { LegacyRef, useRef, useState, ReactNode } from 'react';
import * as S from './SideBar.style';

type IChild = JSX.Element | Array<JSX.Element> | ReactNode;

export default function ScrollableMenuContainer({
  children,
  extraOnTop,
}: {
  children: IChild;
  extraOnTop?: IChild;
}) {
  const [isHover, setIsHover] = useState(false);
  const menuRef: LegacyRef<HTMLDivElement> = useRef(null);
  const menuWrapperRef: LegacyRef<HTMLDivElement> = useRef(null);

  const hasMenuScroll =
    menuRef.current && menuWrapperRef.current
      ? menuRef.current.scrollHeight > menuWrapperRef.current.scrollHeight
      : false;

  return (
    <S.MenuWrapper
      ref={menuWrapperRef}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {extraOnTop}
      <S.ScrollIndicatorTop props={{ isVisible: hasMenuScroll && isHover }} />
      <S.StyledList ref={menuRef}>{children}</S.StyledList>
      <S.ScrollIndicatorBottom props={{ isVisible: hasMenuScroll && isHover }} />
    </S.MenuWrapper>
  );
}
