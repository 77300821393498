import Button from '@mui/material/Button';
import { styled } from '@paas-47lining/layout';

export const StyledButton = styled(Button)<{ props: { isActive?: boolean } }>(
  ({ theme, props: { isActive } }) => ({
    // ...theme.typography.body1,
    padding: '16px 32px',
    textDecoration: 'none',
    color: theme.palette.primary.contrastText,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: 'initial',
    fontFamily: theme.typography.fontFamily,
    letterSpacing: '1.2px',
    width: '100%',
    transition: 'background 300ms',
    backgroundColor: isActive ? theme.palette.sideBarMenuHover : 'transparent',
    ':hover': {
      backgroundColor: '#a2a6a333',
    },
    [theme.breakpoints.down('lg')]: {
      textAlign: 'left',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      fontSize: '14px',
    },
  })
);

export const Icon = styled('div')(({ theme }) => ({
  display: 'inline-block',
  marginRight: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    display: 'block',
    marginRight: 0,
  },
}));
