import { ICustomPalette } from './theme.d';

const white = '#ffffff';

// #232f3e - background
// #ddd    - nav font
// #E5EAEF - second nav background
// #333    - black
// linear-gradient(180deg,#f7dfa5 0,#f0c14b) - yellow button gradient
// #0466C8 - bule
// #007dbc - selected nav item color
// #f1f4f6 - section color
// linear-gradient(180deg,#f67c1b 0,#e15500) - oragne gradient button
// #242F3E - footer background

// #16BF9F - svg green
// #1B98E3 - svg blue
// #232F3E - svg black

export const palette: ICustomPalette = {
  black: '#232F3E',
  white: white,
  grey1: '#f1f4f6',
  grey2: '#f1f4f6',
  grey3: '#f1f4f6',
  grey4: '#f1f4f6',
  grey5: '#232F3E',
  link: '#0165A6',
  primary: {
    contrastText: white,
    dark: '#232f3e',
    main: '#232f3e',
    light: '#232f3e',
  },
  secondary: {
    contrastText: white,
    dark: '#e15500',
    main: '#f67c1b',
    light: '#f0c14b',
  },
  success: {
    contrastText: white,
    main: '#388E3C',
    secondary: '#266MOCK_PAGE8',
  },
  info: {
    contrastText: white,
    main: '#6A717A',
  },
  warning: {
    contrastText: white,
    main: '#FBC02D',
  },
  error: {
    contrastText: white,
    main: '#AD0000',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.6)',
  },
  background: {
    paper: '#232f3e',
  },
  border: {
    primary: '#0165A6',
    secondary: '#AD0000',
    main: '#E0E4E9',
  },
  diagrams: {
    background: '#0474bb',
    blue: '#04a0ff',
    main: '#7da1b1',
    purple: '#6MOCK_PAGEc9e',
    red: '#D12B23',
  },
  page: '#FAFBFC',
  icon: '#FAFBFC',
  divider: '#B2BBC1',
  textField: '#E0E4E9',
  sideBar: '#232f3e',
  sideBarMenu: '#17212C',
  sideBarMenuHover: '#007dbc',
  requestFields: {
    query: '#74bbe8',
    kind: '#a07cfc',
    dataPartition: '#FBC02D',
    aggregation: '#ec87baff',
    limit: '#D12B23',
    offset: '#0bed00',
    sort: '#00d3a2',
    status: '#00f6ff',
    scope: '#ffa100',
    latest: '#f262fc',
  },
};
