import { ReactNode } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import ScrollableMenuContainer from './ScrollableMenuContainer';
import * as S from './SideBar.style';

interface SideBarProps {
  onClose: (e: Event) => void;
  open: boolean;
  variant?: 'permanent' | 'persistent' | 'temporary';
  items: ReactNode;
  userMenu: ReactNode;
}

export default function SideBar({ onClose, open, variant, items, userMenu }: SideBarProps) {
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  return (
    <S.StyledDrawer
      props={{ bgImage: undefined }}
      anchor="left"
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <ScrollableMenuContainer>{items}</ScrollableMenuContainer>
      {!isDesktop && <S.UserMenu>{userMenu}</S.UserMenu>}
    </S.StyledDrawer>
  );
}
