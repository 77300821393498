import {styled} from '@paas-47lining/layout';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export const MenuWrapper = styled('div')(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  display: 'flex',
  flexGrow: 1,
  cursor: 'pointer',
  alignContent: 'center',
  position: 'relative',
  color: theme.palette.white,
  [theme.breakpoints.up('lg')]: {
    position: 'relative',
  },
}));

export const Title = styled(Typography)(
  ({
    theme: {
      spacing,
      typography: { body1 },
      palette: {
        primary: { contrastText },
      },
    },
  }) => ({
    ...body1,
    fontSize: '14px',
    color: contrastText,
    flexGrow: 1,
    alignSelf: 'center',
    marginRight: spacing(2),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  })
);

export const MenuContainer = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  width: 'fit-content',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 0,
    paddingBottom: 0,
  },
}));

export const StyledMenuItem = styled(MenuItem)(
  ({
    theme: {
      breakpoints,
      spacing,
      palette: {
        grey5,
        primary: { contrastText },
      },
    },
  }) => ({
    color: contrastText,
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    [breakpoints.up('lg')]: {
      color: grey5,
    },
  })
);

export const HelpIcon = styled(HelpOutlineIcon)({
  alignSelf: 'center',
});

export const ItemText = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(2),
}));

export const Button = styled(IconButton)(
  ({
    theme: {
      typography: { button },
      palette: {
        primary: { contrastText },
      },
    },
  }) => ({
    ...button,
    marginRight: '52px',
    color: contrastText,
    '@media(min-width: 899px)': {
      marginRight: 0,
    },
  })
);

export const MobileArrow = styled(ArrowRightIcon)<{ props: { isOpen: boolean } }>(
  ({ props: { isOpen } }) => ({
    transition: '250ms',
    transform: `rotate(${isOpen ? '-90deg' : '0deg'})`,
  })
);

export const StyledPopper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.sideBarMenu,
  position: 'absolute',
  borderRadius: '2px',
  zIndex: theme.zIndex.popper,
  height: `calc(100vh - ${theme.height.topBar}px)`,
  width: '100%',
  boxShadow: `0px 6px 10px rgba(0, 0, 0, 0.14)`,
  overflowY: 'auto',
  transform: 'none',
  '@media(min-width: 1024px)': {
    transform: 'translate(-8px, 48px) !important',
    backgroundColor: theme.palette.white,
    height: 'auto',
    width: 'fit-content',
    right: '0',
  },
}));
