import { Hub, Auth } from 'aws-amplify';
import { CognitoUserSession, CognitoUser } from 'amazon-cognito-identity-js';
import { OSDUContext } from '@paas-47lining/layout';
import { useState, useContext, useEffect } from 'react';

interface IUser {
  session: CognitoUserSession | null | undefined;
  attributes: Record<string, string>;
}

export default function useAuthenticatedUser() {
  const [user, setUser] = useState<IUser>({ session: undefined, attributes: {} });
  const { setToastState } = useContext(OSDUContext);

  function getUserData() {
    Auth.currentAuthenticatedUser()
      .then(async (data: CognitoUser & { username: string }) => {
        if (data && !user.session) {
          const session = await Auth.userSession(data);
          const attributeList = await Auth.userAttributes(data);
          const attributes: Record<string, string> = { username: data.username };
          for (const item of attributeList) {
            const key: string = item.Name;
            attributes[key] = item.Value;
          }
          setUser({ session, attributes });
        }
        if (!data && user) {
          setUser({ session: null, attributes: {} });
        }
      })
      .catch(() => setUser({ session: null, attributes: {} }));
  }
  useEffect(() => {
    getUserData();
    Hub.listen('auth', (data) => {
      const {
        payload: { message },
      } = data;
      getUserData();
      if (message) {
        setToastState({ errorSeverity: 'info', message });
      }
    });
  }, []);
  return user;
}
