import { useEffect, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import jwtParser from 'Utils/jwtParser';

interface IRow {
  name: string;
  value: string;
}

export default function JWTPage() {
  const [parsedJWT, setParsedJWT] = useState<Array<IRow>>([]);
  const [rawJWT, setRawJWT] = useState('');

  useEffect(() => {
    Auth.currentSession().then((x) => {
      const rawToken = x.getIdToken().getJwtToken();
      const parsed = jwtParser(rawToken);
      setRawJWT(rawToken);
      const rows: IRow[] = [];
      for (const key of Object.keys(parsed)) {
        const name = key;
        const value = parsed[name];

        rows.push({ name, value: JSON.stringify(value) });
      }
      const sorted = rows.sort((a, b) => a.name.localeCompare(b.name));
      setParsedJWT(sorted);
    });
  }, []);

  function getValues() {
    const elements = [];
    for (const { name, value } of parsedJWT) {
      elements.push(
        <div style={{ display: 'flex', width: '100%', color: 'black' }} key={name}>
          <p style={{ flexBasis: '200px' }}>
            <strong>{name}:</strong>
          </p>
          <p style={{ flexBasis: '200px', flexGrow: 1 }}>{value}</p>
        </div>
      );
    }
    return elements;
  }

  return (
    <div>
      <div>
        <h2>Raw:</h2>
        <p style={{ cursor: 'pointer' }} onClick={() => navigator.clipboard.writeText(rawJWT)}>
          {rawJWT}
        </p>
      </div>
      <h2>Parsed:</h2>
      <div style={{ whiteSpace: 'break-spaces' }}>{getValues()}</div>
    </div>
  );
}
