import Box from '@mui/material/Box';
import { styled } from '@paas-47lining/layout';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export const Wrapper = styled('div')({
  display: 'flex',
});

export const Container = styled(Box)<{ props: { isLoggedIn: boolean } }>(
  ({ theme, props: { isLoggedIn } }) => ({
    paddingTop: `calc(${theme.height.topBar}px + ${theme.spacing(3)})`,
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: theme.palette.grey1,
    minHeight: '100vh',
    maxWidth: '100vw',
    width: '100%',
    alignItems: 'baseline',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      marginLeft: isLoggedIn ? theme.width.drawer : 0,
    },
  })
);

export const Content = styled('div')({
  flexGrow: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const HelpIcon = styled(HelpOutlineIcon)({
  alignSelf: 'center',
});

export const AccountIcon = styled(AccountCircleIcon)({
  alignSelf: 'center',
});

export const IconImage = styled('img')<{ props: { isBig: boolean } }>(({ props: { isBig } }) => ({
  width: isBig ? '48px' : '24px',
}));

export const SkipLink = styled('a')(
  ({
    theme: {
      palette: { white, primary },
    },
  }) => ({
    position: 'absolute',
    top: '10px',
    zIndex: '-1',
    marginLeft: '7px',
    padding: '7px 8px 7px 8px',
    backgroundColor: white,
    border: `1px solid ${primary.light}`,
    borderRadius: '3px',
    textDecoration: 'none',
    ':focus': {
      zIndex: '1200',
    },
  })
);

export const LoadingScreen = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '16px',
  width: '100%',
  height: '100%',
});

export const LoadingLabel = styled('label')({
  display: 'block',
  fontSize: '24px',
});

export const MenuElementWithMenuContainer = styled('div')({});

export const SideMenuContainer = styled('div')(
  ({
    theme: {
      palette: { sideBar },
    },
  }) => ({
    backgroundColor: sideBar,
    maxHeight: 'calc(100vh - 100px)',
    overflow: 'auto',
  })
);
