import { styled } from '@paas-47lining/layout';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: theme.palette.primary.dark,
  paddingRight: 0,
  paddingLeft: 0,
  paddingBottom: `${theme.height.topBarBorder}px`,
}));

export const BackgroundImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 0,
});

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: '0 !important',
  height: theme.height.topBar,
  zIndex: 1,
  justifyContent: 'space-between',
}));

export const LogoContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px 16px',
  margin: 'auto 0',
});

export const SimpleLink = styled(Link)({
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: 'inherit',
  ':visited': {
    color: 'inherit',
  },
});

export const LogoLink = styled(Link)({
  display: 'flex',
  padding: '0',
  margin: '0 0 auto',
  height: '100%',
});

export const Logo = styled('img')({
  objectFit: 'contain',
  width: '438px',
  height: '80px',
  margin: 'auto',
});

export const AWSLogo = styled('img')({
  objectFit: 'contain',
  width: '60px',
  padding: '6px 0 0',
  objectPosition: 'top left',
  margin: '0',
});

export const FortySevenLogo = styled('img')({
  objectFit: 'contain',
  height: '48px',
  objectPosition: 'top left',
  margin: '0',
});

export const LaunchpadLogo = styled('img')({
  objectFit: 'contain',
  height: '60px',
  objectPosition: 'center center',
  margin: '0',
});

export const Container = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});

export const Centered = styled('div')({
  alignSelf: 'center',
  display: 'flex',
});

export const RightContainer = styled('div')({
  justifyContent: 'flex-end',
  display: 'flex',
});

export const WorkloadMenuContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(1),
}));

export const HelpMenuContainer = styled(Centered)({});

export const UserMenuContainer = styled(Centered)({
  marginLeft: '32px',
});

export const Header = styled('h1')(() => ({
  margin: '0 auto',
  padding: '0 12px',
  fontSize: '24px',
  lineHeight: '120%',
}));
