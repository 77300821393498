import { styled } from '@paas-47lining/layout';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';

export const StyledDrawer = styled(Drawer)<{ props: { bgImage?: string } }>(
  ({ theme, props: { bgImage } }) => ({
    '& > div': {
      top: `${theme.height.topBar + theme.height.topBarBorder}px`,
      height: `calc(100vh - ${theme.height.topBar + theme.height.topBarBorder}px)`,
      backgroundColor: theme.palette.sideBar,
      backgroundImage: bgImage ? `url(${bgImage})` : undefined,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom right',
      width: '100%',
      padding: '0',
      [theme.breakpoints.up('md')]: {
        width: '33vw',
      },
      '@media(min-width: 1024px)': {
        width: theme.width.drawer,
      },
    },
  })
);

export const MenuWrapper = styled('div')({
  width: '100%',
  height: '0',
  flexGrow: 1,
  position: 'relative',
});

export const StyledList = styled('div')(
  ({
    theme: {
      palette: { grey4 },
    },
  }) => ({
    padding: '0',
    height: '100%',
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      width: '5px',
      height: '5px',
    },
    scrollbarWidth: 'thin',
    scrollbarHeight: 'thin',
    scrollbarColor: `${grey4} transparent`,
    '::-webkit-scrollbar-track-piece': {
      backgroundColor: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: grey4,
    },
  })
);

export const ScrollIndicatorTop = styled('div')<{ props: { isVisible: boolean } }>(
  ({
    props: { isVisible },
    theme: {
      palette: { white },
    },
  }) => ({
    background: `linear-gradient(180deg, ${white} 0%, transparent 50%, transparent 100%)`,
    opacity: isVisible ? 0.5 : 0,
    width: '100%',
    height: '42px',
    position: 'absolute',
    top: 0,
    left: 0,
    transition: '250ms',
    zIndex: '1',
    pointerEvents: 'none',
  })
);

export const ScrollIndicatorBottom = styled(ScrollIndicatorTop)({
  transform: 'rotate(180deg)',
  top: 'unset',
  bottom: 0,
});

export const UserMenu = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(14),
  marginTop: 'auto',
  borderTop: `1px solid ${theme.palette.border.main}`,
  padding: '16px',
}));

export const DataPartitionWrapper = styled('div')(
  ({
    theme: {
      typography: { body1 },
      palette: {
        primary: { contrastText },
      },
    },
  }) => ({
    width: '100%',
    padding: '8px',
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    input: {
      ...body1,
      fontSize: '14px',
      color: contrastText,
    },
  })
);

export const Input = styled(TextField)(
  ({
    theme: {
      palette: {
        primary: { contrastText },
      },
    },
  }) => ({
    flexGrow: 2,
    padding: '0 6px',
    width: '100%',
    '*:before': {
      borderBottom: `2px solid ${contrastText}4a !important`,
    },
    ':hover *:before': {
      borderBottom: `2px solid ${contrastText}a9 !important`,
    },
    ':focus-within :after': {
      borderBottom: `2px solid ${contrastText}`,
    },
  })
);
