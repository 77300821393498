import './index.css';
import { StrictMode, Suspense } from 'react';
import { CssBaseline } from '@mui/material';
import { Amplify } from '@aws-amplify/core';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import awsTheme from 'layout/theme/awsTheme';
import App from './App';
import awsConfig from './AWSConfig';
import LoadingPage from 'pages/LoadingPage/LoadingPage';
import Navigation from 'components/Navigation/Navigation';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { OSDUProvider } from '@paas-47lining/layout';

Amplify.configure(awsConfig);

const container = document.getElementById('root');
const root = container && createRoot(container);
root &&
  root.render(
    <StrictMode>
      <OSDUProvider theme={awsTheme}>
        <BrowserRouter>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Navigation>
              <Suspense fallback={<LoadingPage />}>
                <App />
              </Suspense>
            </Navigation>
          </LocalizationProvider>
        </BrowserRouter>
      </OSDUProvider>
    </StrictMode>
  );
